export default function Footer() {
    return(
        <>
            <div className="footer">
            <hr />
            <center>© Andrew Tapalla, 2023</center>
            </div>
        </>
    )
}
