import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import './About.css'

export default function About() {

    return (
        <>
        <Container>
      <br />
      <Row>
        <Col>
          <div id="aboutMe">
            <br />
            <div className="intro">
            <p id="Headertesting">Get to know me.</p>
            </div>
            <hr />
            <br />
            <div className="test">
            <p id="testing">I come from a background with little to no knowledge of coding or designing. Throughout college, I prepared myself for a career in law enforcement.</p>
            <p id="testing">As a prior law enforcement officer, I joined law enforcement so I can create change and make a difference in someone's life. I want to serve the community and more importantly people who seek help. However, I decided for a career change not because I fell out of love with my job, but because I came to realize that I can still serve people in so many different other ways than public service. That’s when I came to understand that working within the tech industry, I can still do my public service and significantly impact someone's life by helping people who look for help.</p>
            <p id="testing">So, this is me, Andrew Tapalla - I have perseverance and grit. I am motivated, ethical, and self-guided. I have proven on the field that I can easily adapt to any stressful situation. With my selfless and motivated mindset, I can reassure to everyone that I will make a difference in helping a person, company, or community grow. <br />< br /><strong>This I promise.</strong></p> 
            </div>
          </div>
        </Col>
        <Col>
          <div>
            <img src="/images/profile-image.png" class="img-fluid" id="photoDump"></img>
          </div>
        </Col>
        </Row> 
        <br /><br /><br /><br /><br />
        </Container>
        </>
    );
};