import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import './Design.css'

export default function Experience() {
    return (
        <>
        <br />
        <Container>
            <h1>Designs</h1>
            <br /><br />
            <p>Mobile wallpapers:</p>
            <br />
        <Row>
            <Col>
            <a href="/images/atoswallpaper.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atoswallpaper.png" />
            </a>
            <a href="/images/atos2.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos2.png" />
            </a>
            <a href="/images/atos4.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos4.png" />
            </a>
            <a href="/images/atos6.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos6.png" />
            </a>
            </Col>
        </Row>
        <br />
        <Row>
            <Col>
            <a href="/images/atos7.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos7.png" />
            </a>
            <a href="/images/atos8.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos8.png" />
            </a>
            <a href="/images/atos9.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos9.png" />
            </a>
            <a href="/images/atos10.png" target="_blank" id="wallpaper-image">
                <img id="wallpaper-thumbnail" src="/images/atos10.png" />
            </a>
            </Col>
        </Row>
        <br /><br /><br /><br />
        </Container>
        </>
)}