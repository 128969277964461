import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import './Splash.css'

export default function Splash() {
    return(
      <Container>
      <br /><br /><br />
      <p id="Headertesting">Hello! My name is Andrew! I am a UI/UX Designer based out of Las Vegas, NV.</p>
      <p>I love video games, watching sports, and training jiu-jitsu.</p>
      <br /><br /><br />

      {/* FIRST ROW */}
      <div className="first-row">
        <div className="card-image">
            <img src="/images/flappybird-v2.png" className="rec--image" />
              <div className="card-overlay">
                <div className="card-title">Online Multiplayer Flappybird</div>
                <p>-coming soon-</p>
              </div>
        </div>
        <div className="break-mobile"><br /></div>
        <div className="card-image atos-image">
          <a href="https://www.andrewtapallablog.com/XGuard" target="_blank" className="card-link">
            <img src="/images/atos.png" className="card-image" />
            <div className="card-overlay">
              <div className="card-title">X-Guard</div>
              <p>- view -</p>
            </div>
            </a>
          </div>
      </div>

      {/* SECOND ROW */}
      <div className="break-mobile"><br /></div>
      <div className="second-row">
        <div className="card-image">
          <a href="https://www.andrewtapallablog.com/LVJJ-Shop" target="_blank">
            <img src="images/test3.png" className="rec--image" />
              <div className="card-overlay">
                <div className="card-title">LVJJ Apparel Shop</div>
                <p>- view -</p>
              </div>
            </a>
        </div>
        <div className="break-mobile"><br /></div>
        {/* <div className="card-image">
          <a href="https://andrewtapallablog.com/FlappyBlog" target="_blank">
            <img src="images/test5.png" className="rec--image" />
            <div className="card-overlay">
              <div className="card-title">FlappyBird</div>
              <p>- view -</p>
            </div>
            </a>
        </div> */}
        <div className="card-image">
          <a href="https://andrewtapallablog.com/LogoGame" target="_blank">
          <img src="images/test4.png" className="rec--image" />
            <div className="card-overlay">
              <div className="card-title">Logo Game</div>
              <p>- view -</p>
            </div>
          </a>
        </div>
      </div>
      <br /><br /><br />
      </Container>
    )
}