import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'



import Splash from  './components/Splash/Splash'
import Contact from './components/Contact'
import About from './components/About/About'
import Experience from './components/Education/Education'
import Footer from './components/Footer/Footer'
import Design from  './components/Design/Design'


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <Navbar bg="dark" expand="lg" sticky="top">
            <Container>
            <Navbar.Toggle id="navBar-toggle" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav style={{ width: "100%" }}>
                <Nav.Link href="/" className="navBar-li" style={{color:"#fff"}}>Home</Nav.Link>
                <Nav.Link href="/About" className="navBar-li" style={{color:"#fff"}}>About</Nav.Link>
                <Nav.Link href="/Experience" className="navBar-li" style={{color:"#fff"}}>Experience</Nav.Link>
                <Nav.Link href="/Design" className="navBar-li" style={{color:"#fff"}}>Design</Nav.Link>
                <Nav.Link href="https://www.andrewtapallablog.com" className="navBar-li" style={{color:"#fff"}} target="_blank">Blog</Nav.Link>
                <Nav.Link href="/Contact" className="navBar-li" style={{color:"#fff"}}>Contact</Nav.Link>
              </Nav>
              <Nav>
              <Nav.Link href="https://www.linkedin.com/in/andrewtapalla/" className="icon-link">
                    <img src="/images/linkedin-2.png" height="25" width="25"></img>
              </Nav.Link>
              <Nav.Link href="/images/developerresume.pdf" target="_blank" className="icon-link">
              <img src={process.env.PUBLIC_URL + '/images/document.png'} height="25" width="25" alt="resume" />
            </Nav.Link>
              <Nav.Link href="mailto:andrew.tapalla@gmail.com">
                  <img src="/images/mail.png" height="25" width="25" className="icon-link"></img>
              </Nav.Link>
              </Nav>
              </Navbar.Collapse>
              </Container>
        </Navbar>
          
      <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/About" element={<About />} />
          <Route path="/Experience" element={<Experience />} />
          <Route path="/Design" element={<Design />} />
          <Route path="/Contact" element={<Contact />} />
      </Routes>
      </header>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
