import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import './Education.css'

export default function Experience() {
    return (
    <div>
    <Container>
    <br></br>
      <h1>Work Experience</h1>
      <div className="mobile-break"><br /><br /><br /></div>
    <h3 id="experience-title" className="container-padding"><span>KMJ Web Design</span></h3>
    <p id="font-size" class="subtitle">May 2022 - Present</p>
    <Row className="first-row">
      <Col className="left-side">
        <p>Quality Assurance Engineer</p>
        <div className="padding-test">
          <p className="experience-text">• Spearheaded in conducting mobile testing across various platforms such as Android, Windows, and iOS devices.</p>
          <p className="experience-text">• Performed manual testing, including functionality UI testing.</p>
          <p className="experience-text">• Developed a QA process that ensured quality assurance for the company.</p>
          <p className="experience-text">• Participated in weekly meetings with the development and design team to discuss discovered bugs, bug fixes, and latest builds.</p>
        </div>
      </Col>
    </Row>
    <Row id="software-developer-container">
      <Col className="left-side">
        <p>Software Developer</p>
        <div className="padding-test">
          <p className="experience-text">
            • Worked with a team to develop a school data portal for the state of Nevada to improve the information to share with external audiences and help make non-developer changes on an annual basis.
          </p>
          <p className="experience-text">
          • Efficiently updated Nevada’s school districts webpage which resulted in an easier to understand format that promotes activity and engagement.
          </p>
          <p className="experience-text">
          • Created cross-browser compatible and standards-compliant using Vue.js, which lead the application to be responsive for iOS and Android devices.
          </p>
        </div>
      </Col>
    </Row>
    <div className="mobile-break">
    <br /><br /><br />
    </div>
    <h3 id="experience-title" className="container-padding"><span>Yoovo Design</span></h3>
    <p id="font-size" class="subtitle">May 2022 - Present</p>
    <Row id="yoovo-container">
      <Col className="left-side">
          <p>UI/UX Designer</p>
          <div className="padding-test">
            <p className="experience-text">
              • Worked alongside a team to help develop the user interface of Recollection in Christianity which led to a successful deployment of their webpage.
            </p>
            <p className="experience-text">
              • Developed a website mockup to ensure client satisfaction and quality control before project development.
            </p>
            <p className="experience-text">
              • Managed and created project plans while providing updates to team members.
            </p>
        </div>
      </Col>
    </Row>
    <br /><br /><br />
    <h1>Education</h1>
    <br /><br />
    <div className="education-row">
      <Row id="row-flex">
        <Col>
          <Card className="card-background" border="dark">
            <Card.Title className="card-title">Google UX Design Professional Certificate</Card.Title>
            <hr />
            <Card.Body>
            <Card.Text>UI/UX Designer</Card.Text>
            <Card.Subtitle>May 2022 - Present</Card.Subtitle>
          </Card.Body>
          </Card>
        </Col>
        <Col id="mobile-space">
          <Card className="card-background" border="dark">
            <Card.Title className="card-title">University of Nevada, Las Vegas</Card.Title>
            <hr />
            <Card.Body>
            <Card.Text>Full-Stack Web Development Bootcamp</Card.Text>
            <Card.Subtitle>October 2021 - June 2022</Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
      </Row> 
    </div>

    

    <br /><br />








    {/* <center><h2>Education</h2>
    <Row>
      <Col>
        <Card className="experienceBg" border="dark">
          <Card.Title id="experience-card-title">Google UX Design Professional Certificate</Card.Title>
          <hr />
          <Card.Body>
          <Card.Text>UI/UX Designer</Card.Text>
          <Card.Subtitle>May 2022 - Present</Card.Subtitle>
        </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="experienceBg" border="dark">
          <Card.Title id="experience-card-title">University of Nevada, Las Vegas</Card.Title>
          <hr />
          <Card.Body>
          <Card.Text>Full-Stack Web Development Bootcamp</Card.Text>
          <Card.Subtitle>October 2021 - June 2022</Card.Subtitle>
          </Card.Body>
        </Card>
      </Col>
    </Row> 
    <br /><br /><br />
    </center> */}
    <br /><br />
    </Container>
    </div>
    );
};


    {/* <Card id="kmj-card" border="dark">
      <Card.Title id="experienceTitle"><h3>KMJ Web Design</h3></Card.Title>
      <Card.Body>
      <Card.Text id="experienceText">Quality Assurance Engineer</Card.Text>
      <Card.Subtitle>May 2022 - Present</Card.Subtitle>
      <hr />
      <div class="float-left">
        <p>• Spearheaded in conducting mobile testing across various platforms such as Android, Windows, and iOS devices.</p>
        <br />
        <p>• Performed manual testing, including functionality UI testing.</p>
        <br />
        <p>• Developed a QA process that ensured quality assurance for the company.</p>
        <br />
        <p>• Participated in weekly meetings with the development and design team to discuss discovered bugs, bug fixes, and latest builds.</p>
      </div>
      <Card.Text id="experienceText">Software Developer</Card.Text>
      <Card.Subtitle class="date-of-employment">May 2022 - Present</Card.Subtitle>
      <hr />
      <div class="float-left">
        <p>
          • Worked with a team to develop a school data portal for the state of Nevada to improve the information to share with external audiences and help make non-developer changes on an annual basis.
        </p>
        <br />
        <p>
        • Efficiently updated Nevada’s school districts webpage which resulted in an easier to understand format that promotes activity and engagement.
        </p>
        <br />
        <p>
        • Created cross-browser compatible and standards-compliant using Vue.js, which lead the application to be responsive for iOS and Android devices.
        </p>
      </div>
      </Card.Body>
    </Card>
    <br /><br />
    <Card id="yoovo-card" border="dark">
      <Card.Title id="experienceTitle"><h3>Yoovo Design</h3></Card.Title>
      <Card.Body>
      <Card.Text id="experienceText">UI/UX Designer</Card.Text>
      <Card.Subtitle>May 2022 - September 2022</Card.Subtitle>
      <hr />
      <br />
      <div class="float-left">
      <p>• Worked alongside a team to help develop the user interface of Recollection in Christianity which led to a successful deployment of their webpage.
      </p>
      <br />
      <p>
        • Developed a website mockup to ensure client satisfaction and quality control before project development.
      </p>
      <br />
      <p>
        • Managed and created project plans while providing updates to team members.
      </p>
      </div>
      </Card.Body>
    </Card>
    <br /><br /><br /> */}